import React, { useEffect, useState } from "react";
import momentjs from "moment-timezone";
import { Select } from "antd";
import Loader from "./Loader";
const { Option } = Select;
const PAYMENTTYPE = {
  Invalid: 0,
  FullPayment: 1,
  PayViaInstallments: 2,
  PayViaLoan: 3,
};

import currency from "../../currency";

const styles = {};

const TransactionDetails = (props) => {
  console.log({ props });
  const [currTransaction, setCurrTransaction] = useState(null);
  const [installmentPlan, setInstallmentPlan] = useState(null);
  const [emiPlan, setEmiPlan] = useState(null);
  const [allPackages, setAllPackages] = useState([]);
  const [defaultPackage, setDefaultPackage] = useState(0);
  const [defaultSymbol, setDefaultSymbol] = useState("");
  const [packagesOverrides, setPackagesOverrides] = useState({});

  useEffect(() => {
    if (props.transaction?.installmentPlanId) {
      let planIndex =
        props.transaction.updated_package.installmentPlans.findIndex(
          (plan) => plan._id == props.transaction.installmentPlanId
        );
      setInstallmentPlan(
        planIndex != -1
          ? props.transaction.updated_package.installmentPlans[planIndex]
          : props.transaction.updated_package.installmentPlans[0]
      );
    } else if (props.transaction?.emiPlanId) {
      let planIndex = props.transaction.updated_package.emiPlans.findIndex(
        (plan) => plan._id == props.transaction.emiPlanId
      );
      setEmiPlan(
        planIndex != -1
          ? props.transaction.updated_package.emiPlans[planIndex]
          : props.transaction.updated_package.emiPlans[0]
      );
    }

    if (props.overridesFound.length) {
      let temp_ovr = {};
      props.overridesFound.forEach((ovr) => {
        temp_ovr[ovr.pkg_id] = ovr.overrides;
      });
      setPackagesOverrides(temp_ovr);
    } else {
      setPackagesOverrides({});
    }
    setCurrTransaction(props.transaction);
    setAllPackages(props.packages);
    setDefaultSymbol(currency[props.packages[0].curr].symbol);
    if (!props.currencySelected) {
      props.updateCurrency(
        props.transaction ? props.transaction.curr : props.packages[0].curr
      );
    }
  }, [props]);

  useEffect(() => {
    if (allPackages?.[defaultPackage]) {
      console.log({
        "allPackages[defaultPackage]": allPackages[defaultPackage],
        "currency[allPackages[defaultPackage].curr]":
          currency[allPackages[defaultPackage].curr],
      });
      setDefaultSymbol(currency[allPackages[defaultPackage].curr].symbol);
    }
  }, [defaultPackage]);
  let pluralise = (n) => {
    return ["st", "nd", "rd"][((((n + 90) % 100) - 10) % 10) - 1] || "th";
  };

  let convertPriceToLocalString = function (amount) {
    return parseFloat(String(amount)).toLocaleString("hi");
  };

  let getPaymentType = (trn) => {
    let type = trn.installmentPlanId
      ? PAYMENTTYPE.PayViaInstallments
      : trn.emiPlanId
      ? PAYMENTTYPE.PayViaLoan
      : PAYMENTTYPE.FullPayment;
    return type;
  };

  let getCurrentTotalAmountLeft = (trn) => {
    let type = getPaymentType(trn);
    let coursePackage = trn.updated_package;
    if (coursePackage) {
      let amount = 0;
      let sum = getUnpaidSum(trn, false);

      if (type == PAYMENTTYPE.FullPayment) {
        amount += sum;
        if (!trn.full_trxn_id) {
          amount += Number(currTransaction.updated_package?.price);
          // +
          // (currTransaction.package_snapshot.reg_price || 0) +
          // (currTransaction.package_snapshot.app_fees || 0) -
          // (currTransaction.updated_package.reg_price || 0) -
          // (currTransaction.updated_package.app_fees || 0);
          // if (props.scholarship) amount += props.scholarship;
          // if (trn.code_discount) amount -= trn.code_discount;
        }
      } else if (type == PAYMENTTYPE.PayViaInstallments) {
        amount = sum;
        let planIndex = trn.updated_package.installmentPlans.findIndex(
          (plan) => plan._id == trn.installmentPlanId
        );
        trn.updated_package.installmentPlans[planIndex].installments.forEach(
          (ins, idx) => {
            if (idx >= trn.installment_trxn_ids.length) {
              amount += Number(ins?.amount);
            }
          }
        );
      } else if (type == PAYMENTTYPE.PayViaLoan) {
        amount = sum;
        let planIndex = trn.updated_package.emiPlans.findIndex(
          (plan) => plan._id == trn.emiPlanId
        );
        trn.updated_package.emiPlans[planIndex].emis.forEach((emi, idx) => {
          if (idx >= trn.emi_trxn_ids.length) {
            amount += Number(emi?.amount);
          }
        });
      }
      return Number(amount.toFixed(2));
    }
    return 0;
  };

  let getFinalTotalAmount = (trn) => {
    let type = getPaymentType(trn);
    let coursePackage = trn.updated_package;

    if (coursePackage) {
      let amount = 0;
      let sum = getUnpaidSum(trn, true);
      if (type == PAYMENTTYPE.FullPayment) {
        amount += sum;
        amount += Number(currTransaction.updated_package?.price);
        // +
        // (currTransaction.package_snapshot.reg_price || 0) +
        // (currTransaction.package_snapshot.app_fees || 0) -
        // (currTransaction.updated_package.reg_price || 0) -
        // (currTransaction.updated_package.app_fees || 0);
        // if (props.scholarship) amount += props.scholarship;
        // if (trn.code_discount) amount -= trn.code_discount;
      } else if (type == PAYMENTTYPE.PayViaInstallments) {
        amount = sum;
        let planIndex = trn.updated_package.installmentPlans.findIndex(
          (plan) => plan._id == trn.installmentPlanId
        );
        trn.updated_package.installmentPlans[planIndex].installments.forEach(
          (ins, idx) => {
            amount += Number(ins?.amount);
          }
        );
      } else if (type == PAYMENTTYPE.PayViaLoan) {
        amount = sum;
        let planIndex = trn.updated_package.emiPlans.findIndex(
          (plan) => plan._id == trn.emiPlanId
        );
        trn.updated_package.emiPlans[planIndex].emis.forEach((emi, idx) => {
          amount += Number(emi?.amount);
        });
      }
      return Number(amount.toFixed(2));
    }
    return 0;
  };

  let getUnpaidSum = (transaction, actual) => {
    let registration = Number(transaction.updated_package?.reg_price) || 0;
    let application = Number(transaction.updated_package?.app_fees) || 0;
    let sum = 0;
    if (actual) {
      return sum + registration + application;
    }
    if (!getRegistrationPaid(transaction)) sum += registration;
    if (!getApplicationPaid(transaction)) sum += application;
    console.log({ sum, registration, application });
    return sum;
  };

  let getRegistrationPaid = (transaction) => {
    console.log("getRegistrationPaid");
    console.log({ transaction });
    let paid = false;
    if (transaction) {
      if (!transaction.reg_trxn_id) {
        if (transaction.full_trxn_id) {
          paid = true;
        } else {
          paid = false;
        }
      } else {
        paid = true;
      }
    } else {
      paid = false;
    }
    console.log({ paid });
    return paid;
  };

  let getApplicationPaid = (transaction) => {
    console.log("getApplicationPaid");
    console.log({ transaction });
    let paid = false;
    if (transaction) {
      if (!transaction.application_trxn_id) {
        if (transaction.full_trxn_id) {
          paid = true;
        } else {
          paid = false;
        }
      } else {
        paid = true;
      }
    } else {
      paid = false;
    }
    console.log({ paid });
    return paid;
  };
  let getTotalRelevantAmount = (trn) => {
    let amount = 0;
    if (trn.installmentPlanId) {
      let planIndex = trn.updated_package.installmentPlans.findIndex(
        (el) => el._id == trn.installmentPlanId
      );
      amount = trn.updated_package.installmentPlans[planIndex].amount;
    } else if (trn.emiPlanId) {
      let planIndex = trn.updated_package.emiPlans.findIndex(
        (el) => el._id == trn.emiPlanId
      );
      amount = trn.updated_package.emiPlans[planIndex].amount;
    } else {
      amount = trn.updated_package.price;
    }
    return amount;
  };
  const getTextAmount = (regOrginal, regUpdated, appOriginal, appUpdated) => {
    let amount = 0;
    if (regOrginal != regUpdated) {
      amount += regOrginal - regUpdated;
    }
    if (appOriginal != appUpdated) {
      amount += appOriginal - appUpdated;
    }
    return amount;
  };

  const getDiscountAmount = () => {
    let value_type = props.discount.valuetype;
    let value = props.discount.value;

    if (value_type == 1) {
      return Number(
        (
          (parseFloat(value) *
            (allPackages[defaultPackage].price +
              (packagesOverrides[allPackages[defaultPackage]._id] &&
              packagesOverrides[allPackages[defaultPackage]._id].reg_price
                ? allPackages[defaultPackage].reg_price -
                  packagesOverrides[allPackages[defaultPackage]._id].reg_price
                : 0) +
              (packagesOverrides[allPackages[defaultPackage]._id] &&
              packagesOverrides[allPackages[defaultPackage]._id].app_fees
                ? allPackages[defaultPackage].app_fees -
                  packagesOverrides[allPackages[defaultPackage]._id].app_fees
                : 0) -
              props.scholarship_object &&
            props.scholarship_object[allPackages[defaultPackage]._id] &&
            props.scholarship_object[allPackages[defaultPackage]._id].amount
              ? props.scholarship_object[allPackages[defaultPackage]._id].amount
              : 0)) /
          100
        ).toFixed(2)
      );
    } else {
      return Number(value);
    }
  };

  if (props.loading) {
    return <Loader loading={props.loading} />;
  }
  return (
    <>
      <div key={1}>
        {currTransaction && allPackages.length ? (
          <div
            style={{ padding: "24px 24px" }}
            className={styles["billContainer"]}
          >
            <p style={{ fontSize: "20px", fontWeight: "600" }}>
              Student has selected{" "}
              {currTransaction.curr && <span> {currTransaction.curr}</span>}{" "}
              package
            </p>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <p style={{ fontSize: "18px", fontWeight: "600" }}>
                  Fee Structure
                </p>
                <div className={styles["row"]} style={{}}>
                  <span style={{ fontWeight: "400" }}>Balance Fee : </span>

                  <span style={{ marginLeft: "auto", fontWeight: "400" }}>
                    {currTransaction.currSymbol && (
                      <span> {currTransaction.currSymbol}</span>
                    )}
                    <span>
                      {convertPriceToLocalString(
                        currTransaction.package_snapshot.price
                      )}
                    </span>
                  </span>
                </div>
                {currTransaction.package_snapshot.reg_price > 0 && (
                  <div className={styles["row"]} style={{}}>
                    <span style={{ fontWeight: "400" }}>
                      Registration Fee :{" "}
                    </span>

                    <span style={{ marginLeft: "auto", fontWeight: "400" }}>
                      {currTransaction.currSymbol && (
                        <span> {currTransaction.currSymbol}</span>
                      )}
                      <span>
                        {convertPriceToLocalString(
                          currTransaction.package_snapshot.reg_price
                        )}
                      </span>
                    </span>
                  </div>
                )}
                {currTransaction.package_snapshot.app_fees > 0 ? (
                  <div className={styles["row"]} style={{}}>
                    <span style={{ fontWeight: "400" }}>
                      Application Fee :{" "}
                    </span>

                    <span style={{ marginLeft: "auto", fontWeight: "400" }}>
                      {currTransaction.currSymbol && (
                        <span> {currTransaction.currSymbol}</span>
                      )}
                      <span>
                        {convertPriceToLocalString(
                          currTransaction.package_snapshot.app_fees
                        )}
                      </span>
                    </span>
                  </div>
                ) : null}

                <hr style={{ margin: "4px 0" }} />

                <div className={styles["row"]} style={{}}>
                  <span style={{ fontWeight: "400" }}>Net Program Fee : </span>

                  <span style={{ marginLeft: "auto", fontWeight: "400" }}>
                    {currTransaction.currSymbol && (
                      <span> {currTransaction.currSymbol}</span>
                    )}
                    <span>
                      {convertPriceToLocalString(
                        (currTransaction.package_snapshot.reg_price || 0) +
                          (currTransaction.package_snapshot.app_fees || 0) +
                          currTransaction.package_snapshot.price
                      )}
                    </span>
                  </span>
                </div>
              </div>
              {(currTransaction.package_snapshot.reg_price !=
                currTransaction.updated_package.reg_price ||
                currTransaction.package_snapshot.app_fees !=
                  currTransaction.updated_package.app_fees ||
                props.scholarship > 0 ||
                currTransaction.code_discount > 0) && (
                <div>
                  <p style={{ fontSize: "18px", fontWeight: "600" }}>
                    Updated Fee Structure
                  </p>
                  <div className={styles["row"]} style={{}}>
                    <span style={{ fontWeight: "400" }}>Balance Fee : </span>

                    <span style={{ marginLeft: "auto", fontWeight: "400" }}>
                      {currTransaction.currSymbol && (
                        <span> {currTransaction.currSymbol}</span>
                      )}
                      <span>
                        {convertPriceToLocalString(
                          getTotalRelevantAmount(currTransaction) +
                            Number(props.scholarship || 0) +
                            Number(currTransaction.code_discount || 0)
                          // +
                          //   (currTransaction.package_snapshot.reg_price || 0) +
                          //   (currTransaction.package_snapshot.app_fees || 0) -
                          //   (currTransaction.updated_package.reg_price || 0) -
                          //   (currTransaction.updated_package.app_fees || 0)
                        )}
                      </span>
                    </span>
                  </div>
                  {currTransaction.updated_package.reg_price > 0 ? (
                    <div className={styles["row"]} style={{}}>
                      <span style={{ fontWeight: "400" }}>
                        Registration Fee :{" "}
                      </span>

                      <span style={{ marginLeft: "auto", fontWeight: "400" }}>
                        {currTransaction.currSymbol && (
                          <span> {currTransaction.currSymbol}</span>
                        )}
                        <span>
                          {convertPriceToLocalString(
                            currTransaction.updated_package.reg_price
                          )}
                        </span>
                      </span>
                    </div>
                  ) : null}
                  {currTransaction.updated_package.app_fees ? (
                    <div className={styles["row"]} style={{}}>
                      <span style={{ fontWeight: "400" }}>
                        Application Fee :{" "}
                      </span>

                      <span style={{ marginLeft: "auto", fontWeight: "400" }}>
                        {currTransaction.currSymbol && (
                          <span> {currTransaction.currSymbol}</span>
                        )}
                        <span>
                          {convertPriceToLocalString(
                            currTransaction.updated_package.app_fees
                          )}
                        </span>
                      </span>
                    </div>
                  ) : null}

                  {props.scholarship > 0 ? (
                    <div className={styles["row"]} style={{}}>
                      <span style={{ fontWeight: "400" }}>
                        Scholarship Amount :{" "}
                      </span>

                      <span style={{ marginLeft: "auto", fontWeight: "400" }}>
                        {currTransaction.currSymbol && (
                          <span> {`- ${currTransaction.currSymbol}`}</span>
                        )}
                        <span>
                          {convertPriceToLocalString(props.scholarship)}
                        </span>
                      </span>
                    </div>
                  ) : null}
                  {currTransaction.code_discount > 0 ? (
                    <div className={styles["row"]} style={{}}>
                      <span style={{ fontWeight: "400" }}>
                        Promo Code Discount :{" "}
                      </span>

                      <span style={{ marginLeft: "auto", fontWeight: "400" }}>
                        {currTransaction.currSymbol && (
                          <span> {`- ${currTransaction.currSymbol}`}</span>
                        )}
                        <span>
                          {convertPriceToLocalString(
                            currTransaction.code_discount
                          )}
                        </span>
                      </span>
                    </div>
                  ) : null}

                  <hr style={{ margin: "4px 0" }} />
                  <div className={styles["row"]} style={{}}>
                    <span style={{ fontWeight: "400" }}>
                      Net Program Fee :{" "}
                    </span>

                    <span style={{ marginLeft: "auto", fontWeight: "400" }}>
                      {currTransaction.currSymbol && (
                        <span> {currTransaction.currSymbol}</span>
                      )}
                      <span>
                        {convertPriceToLocalString(
                          currTransaction.package_snapshot.price -
                            currTransaction.code_discount -
                            props.scholarship +
                            (currTransaction.package_snapshot.reg_price || 0) +
                            (currTransaction.package_snapshot.app_fees || 0)
                        )}
                      </span>
                    </span>
                  </div>
                </div>
              )}
            </div>
            {/* {(currTransaction.package_snapshot.reg_price !=
        currTransaction.updated_package.reg_price ||
        currTransaction.package_snapshot.app_fees !=
          currTransaction.updated_package.app_fees) && (
        <div style={{ marginTop: "16px", fontWeight: "600" }}>
          *Note : Please adjust the difference of {currTransaction.currSymbol}
          {getTextAmount(
            currTransaction.package_snapshot.reg_price,
            currTransaction.updated_package.reg_price,
            currTransaction.package_snapshot.app_fees,
            currTransaction.updated_package.app_fees
          )}{" "}
          Registration and Application fees while applying for scholarship.
        </div>
      )} */}

            <hr />

            <div style={{ width: "fit-content" }}>
              <p style={{ fontSize: "18px", fontWeight: "600" }}>
                Payment Status
              </p>
              {currTransaction.updated_package.reg_price > 0 ? (
                <div className={styles["row"]} style={{}}>
                  <span style={{ fontWeight: "400" }}>Registration Fee : </span>

                  <span style={{ marginLeft: "auto", fontWeight: "400" }}>
                    {currTransaction.currSymbol && (
                      <span> {currTransaction.currSymbol}</span>
                    )}
                    <span>
                      {convertPriceToLocalString(
                        currTransaction.updated_package.reg_price
                      )}
                    </span>
                  </span>
                  <span style={{ marginLeft: "8px" }}>
                    {currTransaction.full_trxn_id ||
                    currTransaction.reg_trxn_id ? (
                      <>
                        <span>(</span>
                        <span className="paid">Paid</span>
                        <span>)</span>
                      </>
                    ) : (
                      <>
                        <span>(</span>
                        <span className="unpaid">Unpaid</span>
                        <span>)</span>
                      </>
                    )}
                  </span>
                </div>
              ) : null}
              {currTransaction.updated_package.app_fees > 0 ? (
                <div className={styles["row"]} style={{}}>
                  <span style={{ fontWeight: "400" }}>Application Fee : </span>

                  <span style={{ marginLeft: "auto", fontWeight: "400" }}>
                    {currTransaction.currSymbol && (
                      <span> {currTransaction.currSymbol}</span>
                    )}
                    <span>
                      {convertPriceToLocalString(
                        currTransaction.updated_package.app_fees
                      )}
                    </span>
                  </span>
                  <span style={{ marginLeft: "8px" }}>
                    {currTransaction.full_trxn_id ||
                    currTransaction.application_trxn_id ? (
                      <>
                        <span>(</span>
                        <span className="paid">Paid</span>
                        <span>)</span>
                      </>
                    ) : (
                      <>
                        <span>(</span>
                        <span className="unpaid">Unpaid</span>
                        <span>)</span>
                      </>
                    )}
                  </span>
                </div>
              ) : null}
              {getPaymentType(currTransaction) == PAYMENTTYPE.FullPayment && (
                <>
                  <div
                    className={styles["row"] + " " + styles["bold"]}
                    style={{}}
                  >
                    <span>Balance Fee :</span>

                    <span style={{ marginLeft: "auto", fontWeight: "400" }}>
                      {currTransaction.currSymbol && (
                        <span> {currTransaction.currSymbol}</span>
                      )}
                      <span>
                        {convertPriceToLocalString(
                          getTotalRelevantAmount(currTransaction)
                          // +
                          // - currTransaction.code_discount
                          // props.scholarship
                          // +
                          // (currTransaction.package_snapshot.reg_price || 0) +
                          // (currTransaction.package_snapshot.app_fees || 0) -
                          // (currTransaction.updated_package.reg_price || 0) -
                          // (currTransaction.updated_package.app_fees || 0)
                        )}
                      </span>
                    </span>
                    <span style={{ marginLeft: "8px" }}>
                      {currTransaction.full_trxn_id ? (
                        <>
                          <span>(</span>
                          <span className="paid">Paid</span>
                          <span>)</span>
                        </>
                      ) : (
                        <>
                          <span>(</span>
                          <span className="unpaid">Unpaid</span>
                          <span>)</span>
                        </>
                      )}
                    </span>
                  </div>
                </>
              )}
              {(installmentPlan || emiPlan) && (
                <div className={styles["row"]} style={{}}>
                  <span style={{}}>
                    Balance Fee (
                    {installmentPlan
                      ? installmentPlan.alias || "Installment Plan"
                      : "Emi Plan"}
                    ) :
                  </span>

                  <span style={{ marginLeft: "auto", fontWeight: "400" }}>
                    {currTransaction.currSymbol && (
                      <span> {currTransaction.currSymbol}</span>
                    )}
                    <span>
                      {currTransaction.updated_package.installmentPlans &&
                        convertPriceToLocalString(
                          installmentPlan
                            ? installmentPlan.amount
                            : emiPlan.amount
                        )}
                    </span>
                  </span>
                  {installmentPlan && (
                    <span style={{ marginLeft: "8px" }}>
                      {currTransaction.installment_trxn_ids.length == 0 ? (
                        <>
                          <span>(</span>
                          <span className="unpaid">Unpaid</span>
                          <span>)</span>
                        </>
                      ) : currTransaction.installment_trxn_ids.length <
                        installmentPlan.installments.length ? (
                        <>
                          <span>(</span>
                          <span className="partial-paid">Partially Paid</span>
                          <span>)</span>
                        </>
                      ) : (
                        <>
                          <span>(</span>
                          <span className="paid">Paid</span>
                          <span>)</span>
                        </>
                      )}
                    </span>
                  )}
                  {emiPlan && (
                    <span style={{ marginLeft: "8px" }}>
                      {currTransaction.emi_trxn_ids.length == 0 ? (
                        <>
                          <span>(</span>
                          <span className="unpaid">Unpaid</span>
                          <span>)</span>
                        </>
                      ) : currTransaction.emi_trxn_ids.length <
                        emiPlan.emis.length ? (
                        <>
                          <span>(</span>
                          <span className="partial-paid">Partially Paid</span>
                          <span>)</span>
                        </>
                      ) : (
                        <>
                          <span>(</span>
                          <span className="paid">Paid</span>
                          <span>)</span>
                        </>
                      )}
                    </span>
                  )}
                </div>
              )}
              {(installmentPlan || emiPlan) && (
                <div
                  className={styles["subText"]}
                  style={{ textAlign: "left", marginLeft: "24px" }}
                  role="button"
                >
                  <div>
                    {installmentPlan &&
                      installmentPlan?.installments.map(
                        (installment, installmentIndex) => {
                          console.log({
                            installments: installmentPlan?.installments,
                          });
                          return (
                            <div
                              key={installmentIndex + "installments"}
                              className={styles["row"]}
                            >
                              <span>
                                {installment.alias || (
                                  <>
                                    {installmentIndex + 1}
                                    <sup
                                      style={{
                                        fontWeight: "600",
                                        fontSize: "10px",
                                      }}
                                    >
                                      {pluralise(installmentIndex + 1)}
                                    </sup>{" "}
                                    Installment :
                                  </>
                                )}
                              </span>

                              <span
                                style={{
                                  marginLeft: "auto",
                                  fontWeight: "400",
                                }}
                              >
                                {currTransaction.currSymbol && (
                                  <span> {currTransaction.currSymbol}</span>
                                )}
                                <span>
                                  {convertPriceToLocalString(
                                    installment.amount
                                  )}
                                </span>
                              </span>
                              <span style={{ marginLeft: "8px" }}>
                                {currTransaction.installment_trxn_ids.length >
                                installmentIndex ? (
                                  <>
                                    <span>(</span>
                                    <span className="paid">Paid</span>
                                    <span>)</span>
                                  </>
                                ) : (
                                  <>
                                    <span>(</span>
                                    <span className="unpaid">Unpaid</span>
                                    <span>)</span>
                                  </>
                                )}
                              </span>
                            </div>
                          );
                        }
                      )}
                    {emiPlan &&
                      emiPlan?.emis.map((emi, emiIndex) => {
                        console.log({
                          emis: emiPlan?.emis,
                        });
                        return (
                          <div
                            key={emiIndex + "emis"}
                            className={styles["row"]}
                          >
                            <span>
                              {emiIndex + 1}
                              <sup
                                style={{
                                  fontWeight: "600",
                                  fontSize: "10px",
                                }}
                              >
                                {pluralise(emiIndex + 1)}
                              </sup>{" "}
                              Emi :
                            </span>

                            <span
                              style={{
                                marginLeft: "auto",
                                fontWeight: "400",
                              }}
                            >
                              {currTransaction.currSymbol && (
                                <span> {currTransaction.currSymbol}</span>
                              )}
                              <span>
                                {convertPriceToLocalString(emi.amount)}
                              </span>
                            </span>
                            <span style={{ marginLeft: "8px" }}>
                              {currTransaction.emi_trxn_ids.length >
                              emiIndex ? (
                                <>
                                  <span>(</span>
                                  <span className="paid">Paid</span>
                                  <span>)</span>
                                </>
                              ) : (
                                <>
                                  <span>(</span>
                                  <span className="unpaid">Unpaid</span>
                                  <span>)</span>
                                </>
                              )}
                            </span>
                          </div>
                        );
                      })}
                  </div>
                </div>
              )}
              <hr style={{ margin: "4px 0" }} />
              <div className={styles["row"] + " " + styles["bold"]} style={{}}>
                <span>Total Fees Paid : </span>

                <span style={{ marginLeft: "auto", fontWeight: "400" }}>
                  {currTransaction.currSymbol && (
                    <span> {currTransaction.currSymbol}</span>
                  )}
                  <span>
                    {convertPriceToLocalString(
                      getFinalTotalAmount(currTransaction) -
                        getCurrentTotalAmountLeft(currTransaction)
                    )}
                  </span>
                </span>
              </div>
              <div className={styles["row"] + " " + styles["bold"]} style={{}}>
                <span>Total Fees Unpaid : </span>

                <span style={{ marginLeft: "auto", fontWeight: "400" }}>
                  {currTransaction.currSymbol && (
                    <span> {currTransaction.currSymbol}</span>
                  )}
                  <span>
                    {convertPriceToLocalString(
                      getCurrentTotalAmountLeft(currTransaction)
                    )}
                  </span>
                </span>
              </div>
            </div>
          </div>
        ) : allPackages.length && defaultSymbol ? (
          <div
            style={{ padding: "24px 24px" }}
            className={styles["billContainer"]}
          >
            <div
              className="ar-modal-text-input"
              style={{
                width: "fit-content",
                float: "right",
                padding: 0,
                margin: 0,
              }}
            >
              <Select
                onChange={(value) => {
                  setDefaultPackage(value);
                  setDefaultSymbol(currency[allPackages[value].curr].symbol);
                  props.updateCurrency(allPackages[value].curr);
                }}
                value={defaultPackage}
                style={{ width: "100%" }}
                className="app-dropdown"
              >
                {allPackages &&
                  allPackages.length &&
                  allPackages.map((crs_pkg, index) => (
                    <Option key={index} value={index}>
                      {currency[crs_pkg.curr].symbol}
                    </Option>
                  ))}
              </Select>
            </div>
            <p style={{ fontSize: "20px", fontWeight: "600" }}>
              Student has not selected any package yet
            </p>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <p style={{ fontSize: "18px", fontWeight: "600" }}>
                  Fee Structure
                </p>
                <div className={styles["row"]} style={{}}>
                  <span style={{ fontWeight: "400" }}>Balance Fee : </span>

                  <span style={{ marginLeft: "auto", fontWeight: "400" }}>
                    {defaultSymbol && <span> {defaultSymbol}</span>}
                    <span>
                      {convertPriceToLocalString(
                        allPackages[defaultPackage].price
                      )}
                    </span>
                  </span>
                </div>
                {allPackages[defaultPackage].reg_price > 0 ? (
                  <div className={styles["row"]} style={{}}>
                    <span style={{ fontWeight: "400" }}>
                      Registration Fee :{" "}
                    </span>

                    <span style={{ marginLeft: "auto", fontWeight: "400" }}>
                      {defaultSymbol && <span> {defaultSymbol}</span>}
                      <span>
                        {convertPriceToLocalString(
                          allPackages[defaultPackage].reg_price
                        )}
                      </span>
                    </span>
                  </div>
                ) : null}
                {allPackages[defaultPackage].app_fees > 0 ? (
                  <div className={styles["row"]} style={{}}>
                    <span style={{ fontWeight: "400" }}>
                      Application Fee :{" "}
                    </span>

                    <span style={{ marginLeft: "auto", fontWeight: "400" }}>
                      {defaultSymbol && <span> {defaultSymbol}</span>}
                      <span>
                        {convertPriceToLocalString(
                          allPackages[defaultPackage].app_fees
                        )}
                      </span>
                    </span>
                  </div>
                ) : null}

                <hr style={{ margin: "4px 0" }} />
                <div className={styles["row"]} style={{}}>
                  <span style={{ fontWeight: "400" }}>Net Program Fee : </span>

                  <span style={{ marginLeft: "auto", fontWeight: "400" }}>
                    {defaultSymbol && <span> {defaultSymbol}</span>}
                    <span>
                      {convertPriceToLocalString(
                        (allPackages[defaultPackage].reg_price || 0) +
                          (allPackages[defaultPackage].app_fees || 0) +
                          allPackages[defaultPackage].price
                      )}
                    </span>
                  </span>
                </div>
              </div>
              {((packagesOverrides[allPackages[defaultPackage]._id] &&
                packagesOverrides[allPackages[defaultPackage]._id].reg_price) ||
                (packagesOverrides[allPackages[defaultPackage]._id] &&
                  packagesOverrides[allPackages[defaultPackage]._id]
                    .app_fees) ||
                (props.scholarship_object &&
                  props.scholarship_object[allPackages[defaultPackage]._id] &&
                  props.scholarship_object[allPackages[defaultPackage]._id]
                    .amount) ||
                (props.discount &&
                  props.discount.code &&
                  props.discount.curr == allPackages[defaultPackage].curr &&
                  getDiscountAmount() > 0)) && (
                <div>
                  <div>
                    <p style={{ fontSize: "18px", fontWeight: "600" }}>
                      Updated Fee Structure
                    </p>
                    <div className={styles["row"]} style={{}}>
                      <span style={{ fontWeight: "400" }}>Balance Fee : </span>

                      <span style={{ marginLeft: "auto", fontWeight: "400" }}>
                        {defaultSymbol && <span> {defaultSymbol}</span>}
                        <span>
                          {convertPriceToLocalString(
                            allPackages[defaultPackage].price +
                              (packagesOverrides[
                                allPackages[defaultPackage]._id
                              ] &&
                              packagesOverrides[allPackages[defaultPackage]._id]
                                .reg_price
                                ? allPackages[defaultPackage].reg_price -
                                  packagesOverrides[
                                    allPackages[defaultPackage]._id
                                  ].reg_price
                                : 0) +
                              (packagesOverrides[
                                allPackages[defaultPackage]._id
                              ] &&
                              packagesOverrides[allPackages[defaultPackage]._id]
                                .app_fees
                                ? allPackages[defaultPackage].app_fees -
                                  packagesOverrides[
                                    allPackages[defaultPackage]._id
                                  ].app_fees
                                : 0)
                          )}
                        </span>
                      </span>
                    </div>
                    {allPackages[defaultPackage].reg_price > 0 ? (
                      <div className={styles["row"]} style={{}}>
                        <span style={{ fontWeight: "400" }}>
                          Registration Fee :{" "}
                        </span>

                        <span style={{ marginLeft: "auto", fontWeight: "400" }}>
                          {defaultSymbol && <span> {defaultSymbol}</span>}
                          <span>
                            {convertPriceToLocalString(
                              packagesOverrides[
                                allPackages[defaultPackage]._id
                              ] &&
                                packagesOverrides[
                                  allPackages[defaultPackage]._id
                                ].reg_price
                                ? packagesOverrides[
                                    allPackages[defaultPackage]._id
                                  ].reg_price
                                : allPackages[defaultPackage].reg_price
                            )}
                          </span>
                        </span>
                      </div>
                    ) : null}
                    {allPackages[defaultPackage].app_fees > 0 ? (
                      <div className={styles["row"]} style={{}}>
                        <span style={{ fontWeight: "400" }}>
                          Application Fee :{" "}
                        </span>

                        <span style={{ marginLeft: "auto", fontWeight: "400" }}>
                          {defaultSymbol && <span> {defaultSymbol}</span>}
                          <span>
                            {convertPriceToLocalString(
                              packagesOverrides[
                                allPackages[defaultPackage]._id
                              ] &&
                                packagesOverrides[
                                  allPackages[defaultPackage]._id
                                ].app_fees
                                ? packagesOverrides[
                                    allPackages[defaultPackage]._id
                                  ].app_fees
                                : allPackages[defaultPackage].app_fees
                            )}
                          </span>
                        </span>
                      </div>
                    ) : null}
                  </div>

                  {props.scholarship_object &&
                  props.scholarship_object[allPackages[defaultPackage]._id] &&
                  props.scholarship_object[allPackages[defaultPackage]._id]
                    .amount > 0 ? (
                    <div className={styles["row"]} style={{}}>
                      <span style={{ fontWeight: "400" }}>
                        Scholarship Amount :{" "}
                      </span>

                      <span style={{ marginLeft: "auto", fontWeight: "400" }}>
                        {defaultSymbol && <span> {`- ${defaultSymbol}`}</span>}
                        <span>
                          {convertPriceToLocalString(
                            props.scholarship_object[
                              allPackages[defaultPackage]._id
                            ].amount
                          )}
                        </span>
                      </span>
                    </div>
                  ) : null}
                  {props.discount &&
                  props.discount.code &&
                  props.discount.curr == allPackages[defaultPackage].curr &&
                  getDiscountAmount() > 0 ? (
                    <div className={styles["row"]} style={{}}>
                      <span style={{ fontWeight: "400" }}>
                        Promo Code Discount :{" "}
                      </span>

                      <span style={{ marginLeft: "auto", fontWeight: "400" }}>
                        {defaultSymbol && <span> {`- ${defaultSymbol}`}</span>}
                        <span>
                          {convertPriceToLocalString(getDiscountAmount())}
                        </span>
                      </span>
                    </div>
                  ) : null}
                  {
                    <div className={styles["row"]} style={{}}>
                      <hr style={{ margin: "4px 0" }} />
                      <span style={{ fontWeight: "400" }}>Net Program Fee</span>

                      <span style={{ marginLeft: "auto", fontWeight: "400" }}>
                        {defaultSymbol && <span> {defaultSymbol}</span>}
                        <span>
                          {convertPriceToLocalString(
                            allPackages[defaultPackage].price +
                              -(props.scholarship_object &&
                              props.scholarship_object[
                                allPackages[defaultPackage]._id
                              ] &&
                              props.scholarship_object[
                                allPackages[defaultPackage]._id
                              ].amount
                                ? props.scholarship_object[
                                    allPackages[defaultPackage]._id
                                  ].amount
                                : 0) -
                              (props.discount &&
                              props.discount.code &&
                              props.discount.curr ==
                                allPackages[defaultPackage].curr &&
                              getDiscountAmount() > 0
                                ? getDiscountAmount()
                                : 0) +
                              // +
                              // (packagesOverrides[allPackages[defaultPackage]._id] &&
                              // packagesOverrides[allPackages[defaultPackage]._id]
                              //   .app_fees
                              //   ? packagesOverrides[allPackages[defaultPackage]._id]
                              //       .app_fees
                              //   : allPackages[defaultPackage].app_fees || 0) +
                              // (packagesOverrides[allPackages[defaultPackage]._id] &&
                              // packagesOverrides[allPackages[defaultPackage]._id]
                              //   .reg_price
                              //   ? packagesOverrides[allPackages[defaultPackage]._id]
                              //       .reg_price
                              //   : allPackages[defaultPackage].reg_price)
                              (allPackages[defaultPackage].reg_price || 0) +
                              (allPackages[defaultPackage].app_fees || 0)
                          )}
                        </span>
                      </span>
                    </div>
                  }
                </div>
              )}
            </div>
            {/* {((packagesOverrides[allPackages[defaultPackage]._id] &&
        packagesOverrides[allPackages[defaultPackage]._id].reg_price) ||
        (packagesOverrides[allPackages[defaultPackage]._id] &&
          packagesOverrides[allPackages[defaultPackage]._id].app_fees)) && (
        <div style={{ marginTop: "16px", fontWeight: "600" }}>
          *Note : Please adjust the difference of {defaultSymbol}
          {getTextAmount(
            allPackages[defaultPackage].reg_price,
            packagesOverrides[allPackages[defaultPackage]._id] &&
              packagesOverrides[allPackages[defaultPackage]._id].reg_price
              ? packagesOverrides[allPackages[defaultPackage]._id].reg_price
              : allPackages[defaultPackage].reg_price,
            allPackages[defaultPackage].app_fees,
            packagesOverrides[allPackages[defaultPackage]._id] &&
              packagesOverrides[allPackages[defaultPackage]._id].app_fees
              ? packagesOverrides[allPackages[defaultPackage]._id].app_fees
              : allPackages[defaultPackage].app_fees
          )}{" "}
          Registration and Application fees while applying for scholarship.
        </div>
      )} */}
          </div>
        ) : null}
      </div>
      <div
        style={{
          padding: "24px 24px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        key={2}
      >
        <div>
          {currTransaction &&
          currTransaction.coupon_code &&
          currTransaction.code_discount ? (
            <>
              <span style={{ fontSize: "15px" }}>
                Currently Applied Promocode :{" "}
              </span>
              <span style={{ fontSize: "15px", fontWeight: "600" }}>
                {currTransaction.coupon_code}
              </span>
            </>
          ) : null}
        </div>
        <div>
          {props.discount && props.discount.code ? (
            <>
              <span style={{ fontSize: "15px" }}>
                Last Admin Applied Promocode From Application Review :{" "}
              </span>
              <span style={{ fontSize: "15px", fontWeight: "600" }}>
                {props.discount.code}
              </span>
              <span>
                {" "}
                (Applied By {props.discount.profile.nam},{" "}
                {props.discount.account.ep})
              </span>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default TransactionDetails;
